<template>
    <div v-for="input in inputs">
        {{ input.title }}
        <o-data-lookup 
            v-if="input.dataobject"
            v-model="input.value"
            :data-object="input.dataobject"
            :columns="input.columnDef"
            :bind="sel => { input.value = sel[input.displayColumn]; input.callback(sel[input.resultColumn], sel[input.displayColumn]); }">
        </o-data-lookup>
        <input v-else 
            v-model="input.value"
            @change="event => input.callback(event.target.value)">
    </div>
</template>

<script setup>
    import { ref, defineProps } from 'vue';
    import { getDataObjectById } from 'o365.vue.ts'
    import ODataLookup from 'o365.vue.components.DataLookup.vue';
    import OFilterString from 'o365.vue.components.FilterString.vue';

    const props = defineProps({
        inputs: Array
        });

    const columnDef = ref([
        {
            field: 'Name'
        }]);

</script>